import React, { useEffect, useState } from 'react';
import './MyCustomChatMessage.css'



const MyCustomChatMessage = (props) => {
    const [time, setTime] = useState(new Date());
    const [read, setRead] = useState(new Date());

    const [color, setColor] = useState('#1972F5');

    useEffect(() => {
        setTime(new Date());
        setRead(new Date());
    }, []);

    useEffect(() => {
        if(props.message && props.message.length > 0) {
            let t = '';
            for (let i = 0; i < props.message.length; i++) {
                setTimeout(() => {
                    t += props.message[i];
                    document.getElementById('child'+time).innerHTML = t;
                }, i * 20)
            }
        }
    }, [props.message]);

    return (
        <div className={'message-con-bot'}>
            {props.message && props.message.length && <div id={'child'+time}></div>}
            <span style={{
                    color: color,
                    position: 'absolute',
                    left: '1px',
                    fontSize: '13px'
                }}></span>
        </div>
    )
}

export default MyCustomChatMessage
