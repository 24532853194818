import React from 'react'
import { Button, Box } from '@mui/material';
import './productOrNeed.css';
import {appLanguage} from "../../../select";
import {Langage} from "../../../language";

const ProductOrNeed = (props) => {
    // const { choices } = questions[props.payload.uid]
    // const { setState } = props
    const getText = (obj) => {
        return obj[appLanguage];
    }

    const choices = [{key: 'product', name: getText(Langage.products)}, {key: 'need', name: getText(Langage.needs)}]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {choices.map((item, index) => <Button onClick={() =>  { localStorage.setItem("prodOrNeed", item.key); props.actionProvider.next()}} key={index} variant="outlined">{item.name}</Button> )
        }
        </Box>
    )
}

export default ProductOrNeed