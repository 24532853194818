import {createChatBotMessage, createCustomMessage} from 'react-chatbot-kit';
import SelfieOrChat from '../widgets/selfieOrChat/selfieOrChat';
import MyAvatar from '../components/myAvatar/myAvatar';
import VideoIntro from '../widgets/videoIntro/videoIntro';
import Offers from "../widgets/offers/offers";
import Choices from '../widgets/choices/choices';
import CustomMessage from './CustomMessage';
import OneButton from '../widgets/oneButton/oneButton';
import MyHeader from "../components/myHeader/myHeader";
import UploadImage from '../widgets/Skin/uploadImage/uploadImage';
import ProductOrNeed from '../widgets/Beauty/productOrNeed/productOrNeed';
import MyCustomChatMessage from "../MyCustomChatMessage/MyCustomChatMessage";
import {appLanguage, titleNormalized} from "../select";
import {Langage} from "../language";

const botName = 'cactus';
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const id = urlParams.get('id')  || '2085475'
const app = window.location.hostname.split('.')[0]

const config = {
  botName: botName,
  initialMessages:
      titleNormalized !== 'chatbot-beauty-skin' ?
          [createCustomMessage('', 'custom', {payload: 'start'})] :
          [createChatBotMessage(Langage.expert_recommandation_cosmetique[appLanguage], {withAvatar: false})],
  customComponents: {
    botAvatar: (props) => <MyAvatar {...props} />,
    header: (props) => <MyHeader {...props} />,
    botChatMessage: (props) => <MyCustomChatMessage {...props} />
  },
  customMessages: {
    custom: (props) => <CustomMessage {...props} />
  },
  disableScrollToBottom: true,
  state: {
    imgSelfie: [],
    color_hair: null,
    existInfosCustomer: false,
    uid: null,
    analysis: null,
    id_organization: id,
    id_form: null,
    saveWidgetsResponse: [],
    age: "",
    fullName: "",
    genre: "",
    user: null,
    email: null,
    token: null,
    selfie: null,
    hairType: null,
    recommendationGPT: null,
    app: app,
    json_data: null,
    needs: [
      {
        "key": "REGULATION",
      },
      {
        "key": "RIDES",
      },
      {
        "key": "TACHES",
      },
      {
        "key": "PORES",
      },
      {
        "key": "IMPERFECTIONS",
      },
      {
        "key": "ECLAT",
      },
      {
        "key": "HYDRATATION",
      },
      {
        "key": "SENSIBILITE",
      }
    ],
    myNeeds: [],
  },
  widgets: [
    {
      widgetName: "uploadSkinImage",
      widgetFunc: (props) => <UploadImage {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "prodOrNeed",
      widgetFunc: (props) => <ProductOrNeed {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "videoIntro",
      widgetFunc: (props) => <VideoIntro {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "selfieOrChat",
      widgetFunc: (props) => <SelfieOrChat {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "offers",
      widgetFunc: (props) => <Offers {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "choices",
      widgetFunc: (props) => <Choices {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "oneButton",
      widgetFunc: (props) => <OneButton {...props} />,
      mapStateToProps: []
    }
  ]
};

export default config;
