import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import './uploadImage.css';
import {analysis, upload} from '../../../api/service-skin';
import {createSalonChatCustomer, updatefollowingChat} from '../../../api/service';
import {appLanguage, titleNormalized} from "../../../select";
import {Langage} from "../../../language";

const UploadImage = (props) => {
    const [flagUpload, setFlagUpload] = useState(false);

    const getNewText = (obj) => {
        return obj[appLanguage];
    }

    const handleCapture = async (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const tmp = { "imgSelfie": [...props.state.imgSelfie, file] };
                props.setState((prev) => {
                    return { ...prev, ...tmp };
                });

                const res = await upload(file);
                if (res.data.success) {
                    setFlagUpload(true);
                    localStorage.setItem("uid", res.data.uid);
                    localStorage.setItem("picture", res.data.picture);
                    let analyse = await analysis(res.data.uid, {
                        "traitementRides": true,
                        "traitementRegulation": true,
                        "traitementSensibilite": true,
                        "traitementEclat": true,
                        "traitementHydratation": true,
                        "traitementTaches": true,
                        "traitementImperfections": true,
                        "traitementPores": true
                    }, 47);
                    let myObj = [];
                    Object.keys(analyse.data.scores).forEach((key) => {
                        myObj.push({key, value: analyse.data.scores[key].final});
                    })
                    localStorage.setItem("scores", JSON.stringify(myObj.sort((a,b) => b.value - a.value)));
                    await updatefollowingChat({first_image: res.data.picture}, localStorage.getItem("followingCactusId"));
                    props.actionProvider.next(null);
                }
            }
        }
    };

    return (
        <Box>
            <Button component="label" variant="contained" startIcon={<PhotoCameraIcon />}>
                {getNewText(Langage.take_a_selfie)}
                <input
                    hidden
                    accept="image/*,text/plain"
                    type="file"
                    onChange={(e) => {
                        handleCapture(e.target);
                    }}
                />
            </Button>
            {flagUpload && <div className="success-message">{getNewText(Langage.upload_success)}</div>}
        </Box>
    );
};

export default UploadImage;
