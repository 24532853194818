import React, { useState, useEffect } from 'react';
import './slide2unlock.css';
import {appLanguage} from "../../select";
import {Langage} from "../../language";

export default function SlideToUnlock ({unlocked}) {
    const [sliderPosition, setSliderPosition] = useState(0);
    const [direction, setDirection] = useState(1);
    const [animationCompleted, setAnimationCompleted] = useState(false);
    const [moseDown, setMoseDown] = useState(false);
    const [cookies, setCookies] = useState(false);
    const [showPdf, setShowPdf] = useState('');

    const getText = (obj) => {
        return obj[appLanguage];
    }

    useEffect(() => {
        if(localStorage.getItem('accept_cookies')) {
            setCookies(true);
        }
    }, [])

    // effet demander par dan 
    useEffect(() => {
      if (!animationCompleted) {
          document.querySelector('.slider').style.transition = 'left 0.5s';
          const interval = setInterval(() => {
              setSliderPosition(prevPosition => {
                  const newPosition = prevPosition + (10 * direction);
                  if (newPosition >= 200 || newPosition <= 0) {
                      setDirection(prevDirection => -prevDirection);
                      if (newPosition <= 0) {
                          setAnimationCompleted(true);
                      }
                  }
                  return newPosition;
              });
          }, 20);
          return () => clearInterval(interval);
      }
  }, [direction, animationCompleted]);

    const setCookie = () => {
        setCookies(true);
        localStorage.setItem('accept_cookies', 'true');
    }

    const handleMouseDown = (e) => {
        setMoseDown(true);
    };
  
    const handleMouseMove = (e) => {
        document.querySelector('.slider').style.transition = 'none';
        const newPosition = (e.clientX || e.touches[0].clientX) - (e.target.getBoundingClientRect().left);
        if(!moseDown || newPosition < 0 || newPosition > 230)
            return;
        setSliderPosition(newPosition);
    };
  
    const handleMouseUp = (e) => {
        setMoseDown(false);
      if (sliderPosition >= 200) {
          if(!cookies) {
              alert(getText(Langage.cookies3));
          } else {
              unlocked(true);
          }
      }
      document.querySelector('.slider').style.transition = 'left 0.5s';
      setSliderPosition(0);
    };
  
    return (
        <div>
            <div className="slide-to-unlock">
                <div className="slide-to-unlock-bc"
                     onMouseDown={handleMouseDown}
                     onMouseMove={handleMouseMove}
                     onMouseUp={handleMouseUp}
                     onTouchMove={handleMouseMove}
                     onTouchStart={handleMouseDown}
                     onTouchEnd={handleMouseUp}
                ></div>
                <div className="slider" style={{left: `${sliderPosition}px`, backgroundImage: 'url(/assets/images/logo-gyd.png)', height: '60px', width: '60px'}}></div>
                <div className="unlock-text" style={{display: (sliderPosition > 10 ? 'none' : 'flex')}}>{getText(Langage.start_diag_new)}</div>
            </div>
            {!cookies && <div className="cookie-bar chois-btns">
                <div> {getText(Langage.cookies1)}
                    <a onClick={() => setShowPdf('COOKIES')} style={{textDecoration: "underline"}}> {getText(Langage.cookies2)}</a>
                </div>
                <button className="cc-dismiss" onClick={() => {setCookie()}}>OK</button>
            </div>}
            {showPdf && <div style={{background: "white", position: "absolute", top: 0, left: 0, overflow: 'auto', zIndex: 99999999}}>
                <span onClick={() => setShowPdf('')} style={{float: 'right', color: "black", padding: '8px', fontSize: '20px'}}>x</span>
                {
                    showPdf === 'COOKIES' &&
                    <div style={{padding: '35px 10px', whiteSpace: 'break-spaces', fontSize: '16px', color: "black", textAlign: 'left'}}>
                        <b>Politique de Cookies GYD Beauty</b> <br/>
                        <br/>
                        Date de dernière modification : 30/05<br/>
                        MENTIONS LÉGALES<br/>
                        URL du Site : URL gyd beauty<br/>
                        Contact : support@gydtech.ia<br/>
                        Directeur de la Publication : [Nom]<br/>
                        Éditeur : GYD Tech, société au capital de 1111,00 euros, dont le siège social est situé au 59 route du
                        moulin carron 69570 DARDILLY inscrite au Registre du Commerce et des Sociétés de Lyon sous le numéro
                        [numéro d'immatriculation].<br/>
                        Hébergeur : [Nom de l'hébergeur], [adresse complète], inscrite au Registre du Commerce et des Sociétés
                        de [ville] sous le numéro [numéro d'immatriculation].<br/>
                        <br/>

                        1. Introduction<br/>
                        Bienvenue sur GYD Beauty. Cette Politique de Cookies explique comment nous utilisons des cookies et
                        autres technologies similaires pour reconnaître les utilisateurs lorsqu'ils visitent notre site Web et
                        utilisent notre application mobile GYD Beauty. Elle décrit ce que sont ces technologies, pourquoi nous
                        les utilisons, et vos droits pour contrôler leur utilisation.
                        <br/><br/>
                        2. Que sont les cookies ?<br/>
                        Les cookies sont de petits fichiers de données placés sur votre ordinateur ou appareil mobile lorsque
                        vous visitez un site Web. Les cookies sont largement utilisés par les propriétaires de sites Web pour
                        faire fonctionner leurs sites, pour fonctionner plus efficacement, et pour fournir des informations de
                        rapport.<br/><br/>

                        3. Pourquoi utilisons-nous des cookies ?<br/>
                        Nous utilisons des cookies pour plusieurs raisons. Certains cookies sont nécessaires pour des raisons
                        techniques ; d'autres nous permettent de personnaliser l'expérience utilisateur sur notre site et
                        application. Les types de cookies que nous pouvons utiliser incluent :<br/>
                        <br/>
                        - Cookies strictement nécessaires : Indispensables pour le fonctionnement de notre site Web et
                        application mobile.<br/>
                        - Cookies de performance : Recueillent des informations sur la façon dont vous utilisez notre site et
                        application pour améliorer leurs performances.<br/>
                        - Cookies de fonctionnalité : Mémorisent vos préférences et améliorent les fonctionnalités du site et
                        de l'application.<br/>
                        - Cookies publicitaires : Utilisés pour rendre les messages publicitaires plus pertinents pour vous.
                        <br/><br/>
                        4. Quels types de cookies utilisons-nous et pourquoi ?<br/>
                        Voici une description des catégories de cookies que notre site Web et notre application mobile
                        utilisent ainsi que les raisons de leur utilisation :
                        <br/><br/>
                        Type de cookie<br/>
                        Finalité<br/>
                        Cookies strictement nécessaires<br/>
                        Assurent le bon fonctionnement de notre site Web et application mobile. Exemples : authentification,
                        sécurité.<br/>
                        Cookies de performance<br/>
                        Analysent l'utilisation de notre site et application pour améliorer leurs performances et votre
                        expérience utilisateur.<br/>
                        Cookies de fonctionnalité<br/>
                        Mémorisent vos préférences et améliorent les fonctionnalités du site et de l'application.
                        Cookies publicitaires<br/>
                        Personnalisent les publicités affichées en fonction de vos intérêts.
                        <br/><br/>
                        |
                        <br/><br/>
                        5. Comment pouvez-vous contrôler les cookies ?<br/>
                        Vous avez le droit de décider d'accepter ou de refuser les cookies. Vous pouvez configurer vos
                        préférences en matière de cookies en modifiant les paramètres de votre navigateur ou en utilisant
                        notre outil de gestion des cookies disponible sur notre site et application. Veuillez noter que si
                        vous choisissez de refuser les cookies, certaines fonctionnalités de notre site Web et application
                        pourraient ne pas fonctionner correctement.<br/>
                        <br/><br/>
                        6. Mise à jour de la politique de cookies<br/>
                        Nous pouvons mettre à jour cette Politique de Cookies de temps en temps pour refléter, par exemple,
                        les changements apportés aux cookies que nous utilisons ou pour d'autres raisons opérationnelles,
                        légales ou réglementaires. Nous vous encourageons à consulter régulièrement cette Politique de Cookies
                        pour rester informé de notre utilisation des cookies et des technologies connexes.
                        <br/><br/>
                        7. Contact<br/>
                        Si vous avez des questions concernant notre utilisation des cookies ou cette Politique de Cookies,
                        veuillez nous contacter à : support@gydtech.ia

                    </div>
                }
            </div>}
        </div>
    );
};
